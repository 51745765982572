:root {
    --primary: #000000;
    --primary-dark: #222222;
    --secondary: #444444;
    --dark: #1e293b;
    --light: #f8fafc;
    --gray: #64748b;
    --gray-light: #e2e8f0;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Inter', sans-serif;
    line-height: 1.6;
    color: var(--dark);
    background-color: var(--light);
}

.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

/* ========== HEADER STYLES ========== */
header {
    background-color: white;
    box-shadow: 0 2px 10px rgba(0,0,0,0.05);
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    height: 70px; /* Fixed height for consistent header */
}

.logo-container {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.logo-img {
    height: 30px;
    margin-right: 10px;
}

.logo-text {
    font-size: 24px;
    font-weight: 700;
    color: var(--primary);
}

.nav-links {
    display: flex;
    gap: 30px;
}

.nav-links a {
    text-decoration: none;
    color: var(--dark);
    font-weight: 500;
    transition: color 0.3s;
    padding: 8px 0;
    position: relative;
}

.nav-links a:hover {
    color: var(--primary);
}

.nav-links a::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--primary);
    transition: width 0.3s;
}

.nav-links a:hover::after {
    width: 100%;
}

.mobile-menu-btn {
    display: none;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: var(--dark);
    padding: 8px;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.mobile-menu-btn:hover, .mobile-menu-btn:focus {
    background-color: rgba(0, 0, 0, 0.05);
    color: var(--primary);
}

/* ========== HERO SECTION ========== */
.hero {
    padding: 140px 0 80px;
    background: linear-gradient(135deg, #222222 0%, #000000 100%);
    color: white;
}

.hero-content {
    max-width: 650px;
    padding: 0 20px;
}

h1 {
    font-size: 48px;
    line-height: 1.2;
    margin-bottom: 20px;
    font-weight: 700;
}

.hero p {
    font-size: 20px;
    margin-bottom: 30px;
    opacity: 0.9;
}

.btn {
    display: inline-block;
    padding: 12px 28px;
    background-color: white;
    color: var(--primary);
    text-decoration: none;
    border-radius: 6px;
    font-weight: 600;
    transition: all 0.3s;
    border: 2px solid transparent;
    text-align: center;
    max-width: 100%; /* Prevent overflow on smaller screens */
}

.btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
}

.btn-secondary {
    background-color: transparent;
    border: 2px solid white;
    color: white;
    margin-left: 15px;
}

.btn-secondary:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

/* ========== SECTION STYLES ========== */
section {
    padding: 80px 0;
}

h2 {
    font-size: 36px;
    margin-bottom: 20px;
    text-align: center;
}

.section-intro {
    text-align: center;
    max-width: 700px;
    margin: 0 auto 50px;
    color: var(--gray);
    font-size: 18px;
}

/* ========== SERVICES SECTION ========== */
.services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
    margin-top: 40px;
}

.service-card {
    background: white;
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0 5px 20px rgba(0,0,0,0.05);
    transition: transform 0.3s, box-shadow 0.3s;
}

.service-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0,0,0,0.1);
}

.service-icon {
    background-color: var(--primary);
    color: white;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    margin-bottom: 20px;
}

.service-title {
    font-size: 22px;
    margin-bottom: 15px;
    font-weight: 600;
}

/* ========== PRODUCTS SECTION ========== */
.products {
    background-color: #f1f5f9;
    padding: 80px 0;
}

.products-grid {
    display: grid;
    grid-template-areas:
    "main main"
    "left right";
    gap: 30px;
}

.product-card {
    background: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 5px 20px rgba(0,0,0,0.05);
    transition: transform 0.3s, box-shadow 0.3s;
    height: 100%;
}

.product-card:first-child {
    grid-area: main;
}

.product-card:nth-child(2) {
    grid-area: left;
}

.product-card:nth-child(3) {
    grid-area: right;
}

.product-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0,0,0,0.1);
}

.product-card-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
}

@media (min-width: 992px) {
    .product-card:first-child .product-card-inner {
        flex-direction: row;
    }

    .product-card:first-child .product-img-container {
        width: 40%;
        height: auto;
    }

    .product-card:first-child .product-content {
        width: 60%;
    }
}

.product-img-container {
    width: 100%;
    height: 200px;
    overflow: hidden;
}

.product-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.product-card:hover .product-img {
    transform: scale(1.05);
}

.product-content {
    padding: 25px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.product-title {
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: 600;
}

.product-description {
    color: var(--gray);
    margin-bottom: 20px;
}

.product-content .btn-product {
    margin-top: auto;
    align-self: flex-start;
}

.btn-product {
    display: inline-block;
    padding: 10px 20px;
    background-color: var(--primary);
    color: white;
    text-decoration: none;
    border-radius: 6px;
    font-weight: 500;
    transition: all 0.3s;
    border: none;
}

.btn-product:hover {
    background-color: var(--primary-dark);
    transform: translateY(-2px);
}

/* ========== CONTACT SECTION ========== */
.contact {
    background-color: white;
}

.contact-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
    align-items: start;
}

.contact-info {
    padding-right: 30px;
}

.contact-info h3 {
    font-size: 28px;
    margin-bottom: 20px;
}

.contact-info p {
    margin-bottom: 20px;
    color: var(--gray);
}

.contact-method {
    display: flex;
    gap: 15px;
    align-items: center;
    margin-bottom: 15px;
}

.contact-icon {
    width: 40px;
    height: 40px;
    background-color: var(--primary);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.contact-form {
    background: white;
    border-radius: 10px;
    padding: 40px;
    box-shadow: 0 10px 30px rgba(0,0,0,0.05);
}

.form-group {
    margin-bottom: 20px;
}

.form-label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
}

.form-input,
.form-textarea {
    width: 100%;
    padding: 12px 15px;
    border: 1px solid var(--gray-light);
    border-radius: 6px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.form-input:focus,
.form-textarea:focus {
    outline: none;
    border-color: var(--primary);
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}

.form-textarea {
    min-height: 120px;
    resize: vertical;
}

.form-button {
    background-color: var(--primary);
    color: white;
    border: none;
    padding: 12px 25px;
    border-radius: 6px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    font-size: 16px;
}

.form-button:hover:not(:disabled) {
    background-color: var(--primary-dark);
    transform: translateY(-2px);
}

/* ========== FOOTER STYLES ========== */
footer {
    background-color: var(--dark);
    color: white;
    padding: 60px 0 20px;
}

.footer-grid {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    gap: 40px;
    margin-bottom: 40px;
}

.footer-col h4 {
    font-size: 18px;
    margin-bottom: 20px;
    position: relative;
}

.footer-col h4::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -8px;
    width: 40px;
    height: 2px;
    background-color: var(--primary);
}

.footer-col p {
    color: #94a3b8;
    margin-bottom: 20px;
}

.footer-links {
    list-style: none;
}

.footer-links li {
    margin-bottom: 10px;
}

.footer-links a {
    color: #94a3b8;
    text-decoration: none;
    transition: color 0.3s;
}

.footer-links a:hover {
    color: white;
}

.footer-bottom {
    border-top: 1px solid rgba(255,255,255,0.1);
    padding-top: 20px;
    text-align: center;
    color: #94a3b8;
    font-size: 14px;
}

/* ========== ANIMATION CLASSES ========== */
.fade-in {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease, transform 0.6s ease;
}

.fade-in.active {
    opacity: 1;
    transform: translateY(0);
}

.delay-1 {
    transition-delay: 0.1s;
}

.delay-2 {
    transition-delay: 0.2s;
}

.delay-3 {
    transition-delay: 0.3s;
}

/* ========== FORM STATUS STYLES ========== */
.form-success {
    text-align: center;
    padding: 30px 0;
}

.form-success h3 {
    font-size: 24px;
    margin-bottom: 15px;
    color: #2b8a3e;
}

.form-success p {
    margin-bottom: 25px;
    color: var(--gray);
}

.form-error-message {
    color: #e53e3e;
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 4px;
    background-color: rgba(229, 62, 62, 0.1);
}

.form-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    transform: none;
}

/* ========== RESPONSIVE STYLES ========== */
@media (max-width: 1100px) {
    .btn {
        padding: 10px 20px;
        font-size: 15px;
    }

    .btn-secondary {
        margin-left: 10px;
    }
}

@media (max-width: 991px) {
    /* Product grid for tablets */
    .products-grid {
        grid-template-areas:
      "main"
      "left"
      "right";
    }

    /* Contact layout for tablets */
    .contact-container {
        grid-template-columns: 1fr;
        gap: 40px;
    }

    .contact-info {
        padding-right: 0;
    }

    /* Footer layout for tablets */
    .footer-grid {
        grid-template-columns: 1fr 1fr;
        gap: 30px;
    }

    /* Product cards for tablets */
    .product-card-inner {
        flex-direction: column !important; /* Override desktop layout for all cards */
    }

    .product-img-container {
        width: 100% !important;
    }

    .product-content {
        width: 100% !important;
    }

    /* Fix button width on hover for tablets */
    .btn:hover, .btn-product:hover {
        transform: translateY(-2px);
        max-width: 100%;
        width: auto;
    }
}

@media (max-width: 768px) {
    /* Header for mobile */
    .header-container {
        padding: 12px 15px;
    }

    .logo-text {
        font-size: 20px;
    }

    /* Fix hamburger button position and hover */
    .mobile-menu-btn {
        display: block;
        position: relative;
        margin-left: auto;
        z-index: 10;
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    /* Mobile navigation */
    .nav-links {
        display: none;
    }

    .nav-links.open {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 70px;
        left: 0;
        right: 0;
        background: white;
        padding: 20px;
        box-shadow: 0 5px 15px rgba(0,0,0,0.1);
        z-index: 5;
    }

    .nav-links a {
        padding: 12px 0;
        font-size: 16px;
        border-bottom: 1px solid var(--gray-light);
        width: 100%;
    }

    .nav-links a:last-child {
        border-bottom: none;
    }

    /* Hero section for mobile */
    h1 {
        font-size: 36px;
    }

    .hero {
        padding: 110px 0 60px;
    }

    .hero-content {
        padding: 0;
    }

    /* Consistent container padding on mobile */
    .container {
        padding: 0 20px;
    }

    /* Button layout for mobile */
    .btn, .btn-secondary {
        display: block;
        width: 100%;
        margin: 10px 0;
        text-align: center;
    }

    .btn-secondary {
        margin-left: 0;
    }

    /* Services grid for mobile */
    .services-grid {
        grid-template-columns: 1fr;
        gap: 20px;
    }

    /* Contact form for mobile */
    .contact-form {
        padding: 25px 20px;
    }

    /* Footer for mobile */
    .footer-grid {
        grid-template-columns: 1fr;
    }

    /* Section spacing for mobile */
    section {
        padding: 60px 0;
    }
}

@media (max-width: 480px) {
    /* Further adjustments for small phones */
    h1 {
        font-size: 30px;
    }

    .hero p {
        font-size: 16px;
    }

    h2 {
        font-size: 28px;
    }

    .section-intro {
        font-size: 16px;
    }

    .service-card, .product-card, .contact-form {
        border-radius: 8px;
    }

    .product-img-container {
        height: 180px;
    }
}

/* ========== PRINT STYLES ========== */
@media print {
    .mobile-menu-btn, .nav-links, .btn, .btn-product, .form-button {
        display: none;
    }

    header {
        position: static;
        box-shadow: none;
    }

    .hero, section, footer {
        padding: 20px 0;
        margin: 0;
    }

    h1, h2, h3 {
        page-break-after: avoid;
    }

    p, .product-description {
        page-break-inside: avoid;
    }

    .container {
        max-width: 100%;
    }
}